import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import noop from 'lodash/noop';
import snakeCase from 'lodash/snakeCase';
import moment from 'moment';
import { bool, func, number, string } from 'prop-types';
import { Field } from 'redux-form';
import { change } from 'redux-form';

import { CANCEL_MODE, CORRECT_MODE, EDIT_MODE } from 'constants/common/crud';
import { DATE_FORMAT } from 'constants/datetime';
import { FORM_NAME } from 'constants/outgoing-invoice';
import { PIWIK_ACTION_REVENUE_CHANGE_DUE_DATE, PIWIK_CATEGORY_REVENUE } from 'constants/piwik';
import { InvoiceStatuses } from 'constants/statuses';
import HintImage from 'images/hint.svg';
import { outgoingInvoiceSelector } from 'reducers/form';
import { t } from 'shared/utils';
import { serverValidationChecker as checker } from 'shared/utils/form-checking';
import { piwikHelpers } from 'shared/utils/piwik';
import BoxWithButton from 'components/BoxWithButton/BoxWithButton';
import Card from 'components/Card';
import CategorySearch from 'components/CategorySearch/CategorySearch';
import If from 'components/Conditions/If';
import { Checkbox, CurrencyFieldWrapper, DateField, TextField } from 'components/Form';
import SelectField from 'components/Form/SelectField';
import { CategoryChangedConfirmationModal } from 'components/LineItems/components/CategoryChangedConfirmationModal/CategoryChangedConfirmationModal';
import { DatevWarningModal } from 'components/LineItems/components/DatevWarningModal/DatevWarningModal';
import InvoiceStatusBadge from 'components/Table/InvoiceStatusBadge/InvoiceStatusBadge';
import { VatIdModal } from 'components/VatIdModal/VatIdModal';

import Client from './Client';
import Template from './Template';

import styles from './CreateInvoiceSection.module.css';

export const FormField = ({ name, dataId, readonly, placeholder, ...rest }) => (
  <div className={styles.formGroup}>
    <Field
      id={name}
      dataId={dataId}
      name={name}
      checker={checker}
      disabled={readonly}
      label={t(`revenue.form.${snakeCase(name)}`)}
      placeholder={placeholder && t(`revenue.form.${snakeCase(name)}`)}
      {...rest}
    />
  </div>
);

const defaultPaymentMethodOptions = [
  { value: '10', label: 'Barzahlung' }, // Cash payment is valid for both
  { value: '59', label: 'Lastschrift (innerhalb SEPA)' }, // SEPA Direct Debit
  { value: '58', label: 'Überweisung (innerhalb SEPA)' }, // SEPA Bank Transfer
];

const outsideSepaOptions = [
  { value: '10', label: 'Barzahlung' }, // Cash payment remains valid outside SEPA
  { value: '30', label: 'Überweisung (nur außerhalb SEPA)' }, // Bank Transfer outside SEPA
];

FormField.propTypes = {
  name: string.isRequired,
  readonly: bool,
  dataId: string,
};

const CreateInvoiceSection = ({
  discountGrant,
  hasUploadSection = false,
  isReadonly,
  status,
  amendmentType,
  paymentRemindersCount,
  client,
  creationDate,
  isCorrectInvoice = false,
  onClientSelect = noop,
  newInvoice = false,
  lineCategories,
  selectedCategory,
  setShowCategoryChangeConfirmation,
  showCategoryChangeConfirmation,
  category,
  isDeprecatedCategory,
  handleSelectCategory,
  crudMode,
  showVatIdWarning,
  setShowVatIdWarning,
  showDifferentCategoryWarning,
  setShowDifferentCategoryWarning,
}) => {
  const dispatch = useDispatch();

  const [paymentMethodOptions, setPaymentMethodOptions] = React.useState(
    defaultPaymentMethodOptions
  );
  const [selectedPaymentTypeCode, setSelectedPaymentTypeCode] = useState('49'); // Default value

  const updatePaymentTypeCode = (isSepa) => {
    if (isSepa) {
      setPaymentMethodOptions(defaultPaymentMethodOptions);
      dispatch(change(FORM_NAME, 'paymentTypeCode', '58'));
      setSelectedPaymentTypeCode('58');
    } else {
      setPaymentMethodOptions(outsideSepaOptions);
      dispatch(change(FORM_NAME, 'paymentTypeCode', '30'));
      setSelectedPaymentTypeCode('30');
    }
  };

  useEffect(() => {
    if (client) {
      updatePaymentTypeCode(client.isSepa);
    } else {
      setPaymentMethodOptions(defaultPaymentMethodOptions);
      dispatch(change(FORM_NAME, 'paymentTypeCode', '10'));
    }
  }, [client]);

  const trackDueDateChange = () => {
    if (!isCorrectInvoice) return;

    piwikHelpers.trackEvent(PIWIK_CATEGORY_REVENUE, PIWIK_ACTION_REVENUE_CHANGE_DUE_DATE);
  };
  const isCancellationInvoice = amendmentType === CANCEL_MODE;
  const isEditInvoice = crudMode === EDIT_MODE;

  const handlePaymentTypeCodeChange = (_event, newValue) => {
    setSelectedPaymentTypeCode(newValue);
  };

  return (
    <Card className={cx(styles.main, { [styles.withUploader]: hasUploadSection })}>
      <Card.Header className={styles.header}>
        <div className={styles.headerLeft}>{t('revenue.form.header_data')}</div>
        {!hasUploadSection && !isCancellationInvoice && (
          <InvoiceStatusBadge
            status={newInvoice ? InvoiceStatuses.DRAFT : status}
            remindersCount={paymentRemindersCount}
          />
        )}
      </Card.Header>
      <Card.Body>
        <div
          className={cx(styles.columns, styles.columnsPadded, {
            [styles.columnsJoined]: hasUploadSection,
          })}
        >
          <div className={cx(styles.column, { [styles.columnWithUploader]: hasUploadSection })}>
            <FormField
              name="subject"
              dataId="OutgoingInvoicePage:input-subject"
              readonly={isReadonly('subject')}
              component={TextField}
            />
            <Client
              selectDataIds={{
                container: 'OutgoingInvoicePage:select-client',
                input: 'OutgoingInvoicePage:input-client',
                option: 'OutgoingInvoicePage:option-client',
              }}
              onChange={onClientSelect}
              readonly={isReadonly('client')}
            />
          </div>
          <div className={cx(styles.column, { [styles.columnWithUploader]: hasUploadSection })}>
            <FormField
              name="number"
              dataId="OutgoingInvoicePage:input-number"
              readonly={!hasUploadSection}
              required={hasUploadSection}
              component={TextField}
              disabledPlaceHolder={t('revenue.form.number_disabled')}
            />
            <FormField
              name="dueDate"
              component={DateField}
              dataIds={{ input: 'OutgoingInvoicePage:input-due-date' }}
              onChange={trackDueDateChange}
              minDate={moment(creationDate, DATE_FORMAT)}
              disabled={isReadonly('dueDate')}
              required
            />
            <Field
              name="discountGrant"
              disabled={isReadonly('discountGrant')}
              dataIds={{
                input: 'OutgoingInvoicePage:input-discount-grant',
                label: 'OutgoingInvoicePage:label-discount-grant',
              }}
              component={Checkbox}
              className={styles.discountCheckbox}
            >
              {t('revenue.form.discount_grant')}
            </Field>
            <div className={styles.columns}>
              <div className={cx(styles.column, styles.withLabel)}>
                <div className={styles.formGroup}>
                  <CurrencyFieldWrapper
                    name="discountPercentage"
                    dataId="OutgoingInvoicePage:input-discount-percentage"
                    precision={2}
                    isRawField
                    translationBase="revenue.form"
                    component={TextField}
                    readonly={!discountGrant || isReadonly('discountPercentage')}
                    isCurrencySignVisible={false}
                  />
                </div>
                <span>%</span>
              </div>
              <div className={cx(styles.column, styles.withLabel)}>
                <FormField
                  name="discountPeriod"
                  component={DateField}
                  dataIds={{ input: 'OutgoingInvoicePage:input-discount-period' }}
                  readonly={!discountGrant || isReadonly('discountPeriod')}
                  minDate={moment(creationDate, 'DD.MM.YYYY')}
                />
              </div>
            </div>
            <div className={styles.columns}>
              <div className={styles.column}>
                <FormField
                  name="servicePeriod"
                  readonly={isReadonly('servicePeriod')}
                  component={TextField}
                  dataId="OutgoingInvoicePage:input-service-period"
                />
              </div>
              <div className={styles.column}>
                <FormField
                  name="creationDate"
                  dataIds={{ input: 'OutgoingInvoicePage:input-date-creation' }}
                  readonly={isReadonly('creationDate')}
                  component={DateField}
                  required
                />
              </div>
            </div>
            <If ok={!hasUploadSection}>
              <Template readonly={isReadonly('template')} />
            </If>
            <If
              ok={
                (crudMode === CORRECT_MODE && isDeprecatedCategory) ||
                (isDeprecatedCategory && isEditInvoice && !isReadonly())
              }
            >
              <BoxWithButton
                isButtonDisabled
                image={HintImage}
                content={t('datev.deprecated_category_warning')}
                dataId="Outgoinginvoices-CreateInvoiceSection:orange-warning-container"
              />
            </If>
            <FormField
              name="invoiceLineCategoryId"
              dataId="OutgoingInvoice:select-category"
              label={`${t('recurring_expenses.form.fields.category')} *`}
              component={CategorySearch}
              categories={lineCategories}
              selectedItem={selectedCategory}
              initialSelectedItem={category}
              initialInputValue={category?.name}
              onSelectCategory={handleSelectCategory}
              disabled={isReadonly('invoiceLineCategoryId')}
              withIcon={!isReadonly('invoiceLineCategoryId')}
              withHint
            />
            <Field
              id="paymentTypeCode"
              name="paymentTypeCode"
              component={SelectField}
              options={paymentMethodOptions}
              label="Zahlungsarten:"
              initialValue="59"
              onChange={handlePaymentTypeCodeChange}
              disabled={isReadonly('paymentTypeCode')}
              required
            />
            {selectedPaymentTypeCode === '59' && (
              <>
                <FormField
                  name="directDebitMandateId"
                  dataId="OutgoingInvoicePage:input-direct-debit-mandate"
                  component={TextField}
                  label={t('revenue.form.direct_debit_mandate')}
                  readonly={isReadonly('directDebitMandateId')}
                  required
                />
                <FormField
                  name="creditorReferenceId"
                  dataId="OutgoingInvoicePage:input-direct-debit-mandate"
                  component={TextField}
                  label={t('revenue.form.creditor_reference')}
                  readonly={isReadonly('creditorReferenceId')}
                  required
                />
              </>
            )}
          </div>
        </div>

        <DatevWarningModal
          isOpen={!showVatIdWarning && showDifferentCategoryWarning}
          onConfirm={() => setShowDifferentCategoryWarning(false)}
        />

        <CategoryChangedConfirmationModal
          isOpen={!showVatIdWarning && showCategoryChangeConfirmation}
          onConfirm={() => setShowCategoryChangeConfirmation(false)}
        />
        <VatIdModal isOpen={showVatIdWarning} onConfirm={() => setShowVatIdWarning(false)} />
      </Card.Body>
    </Card>
  );
};

CreateInvoiceSection.propTypes = {
  isReadonly: func.isRequired,
  hasUploadSection: bool,
  discountGrant: bool,
  creationDate: string,
  status: string,
  paymentRemindersCount: number,
  isCorrectInvoice: bool,
  onClientSelect: func,
  newInvoice: bool,
  amendmentType: string,
  isDraft: bool,
};

const mapStateToProps = (s) => ({
  discountGrant: outgoingInvoiceSelector(s, 'discountGrant'),
  isDraft: outgoingInvoiceSelector(s, 'draft'),
  isPaidByCash: outgoingInvoiceSelector(s, 'paidByCash'),
  creationDate: outgoingInvoiceSelector(s, 'creationDate'),
  status: outgoingInvoiceSelector(s, 'status'),
  paymentRemindersCount: outgoingInvoiceSelector(s, 'paymentRemindersCount'),
  amendmentType: outgoingInvoiceSelector(s, 'amendmentType'),
});

export default connect(mapStateToProps)(CreateInvoiceSection);
