import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import { isEmpty } from 'lodash';
import { bool, func, number, shape } from 'prop-types';

import {
  clearFiltersToInitial as clearFiltersToInitialAction,
  getIncomingInvoices as getIncomingInvoicesAction,
} from 'actions/incoming-invoices';
import {
  PIWIK_ACTION_TAB_EXPENSES_DIAGRAM,
  PIWIK_ACTION_TAB_EXPENSES_TABLE,
  PIWIK_CATEGORY_EXPENSES,
} from 'constants/piwik';
import paths from 'routes/paths';
import { objectHasValues, t } from 'shared/utils';
import { useDebouncedHasAnyFilters } from 'shared/utils/hooks/useDebouncedHasAnyFilters';
import { useRefetchOnPropsChange } from 'shared/utils/hooks/useRefetchOnPropsChange';
import { piwikHelpers } from 'shared/utils/piwik';
import EmptyEntryPage from 'components/EmptyEntryPage/EmptyEntryPage';
import IncomingInvoiceEmptyStateImage from 'components/EmptyEntryPage/images/IncomingInvoice';
import Loading from 'components/Loading';
import Tabs from 'components/Tabs';
import sharedStyles from 'components/Tabs/Tabs.module.css';

import ExpensesChart from '../Expenses/Chart';
import Table from './Table';

import localStyles from './IncomingInvoices.module.css';

const styles = { ...sharedStyles, ...localStyles };

const sections = [
  {
    component: Table,
    heading: t('expenses.tabs.table'),
    id: 'table',
  },
  {
    component: ExpensesChart,
    heading: t('expenses.tabs.chart'),
    id: 'chart',
  },
];

const switchTab = (activeTabIndex) => {
  if (activeTabIndex === 0) {
    piwikHelpers.trackEvent(PIWIK_CATEGORY_EXPENSES, PIWIK_ACTION_TAB_EXPENSES_TABLE);
  }
  if (activeTabIndex === 1) {
    const conversionId = 11;
    piwikHelpers.trackGoal(conversionId);
    piwikHelpers.trackEvent(PIWIK_CATEGORY_EXPENSES, PIWIK_ACTION_TAB_EXPENSES_DIAGRAM);
  }
};

const IncomingInvoices = ({
  areIncomingInvoicesEmpty,
  isFetching,
  push,
  getIncomingInvoices,
  sorting,
  parsedFilters,
  pagination,
  clearFiltersToInitial,
}) => {
  /**
   * This flag is initially set to false and is set to true after first fetch
   * for incoming invoices comes back. It is used for displaying the initial loader.
   */
  const [areIncomingInvoicesFetched, setAreIncomingInvoicesFetched] = useState(false);
  const debouncedHasAnyFiltersActive = useDebouncedHasAnyFilters(parsedFilters);

  useEffect(
    () => () => {
      clearFiltersToInitial();
    },
    [clearFiltersToInitial]
  );

  useRefetchOnPropsChange({
    fetchFunction: async ({
      pagination: newPagination,
      sorting: newSorting,
      overwrittenValues,
      parsedFilters: newParsedFilters,
    }) => {
      const isIndexClassicView = objectHasValues(newParsedFilters);

      await getIncomingInvoices({ ...newPagination, ...overwrittenValues }, newSorting, {
        isIndexClassicView,
      });
      setAreIncomingInvoicesFetched(true);
    },
    props: { parsedFilters, pagination, sorting },
  });

  if (!areIncomingInvoicesFetched) {
    return (
      <div className={styles.page}>
        <div className={styles.main}>
          <Loading />
        </div>
      </div>
    );
  }

  const isEmptyStateVisible =
    !isFetching && areIncomingInvoicesEmpty && !debouncedHasAnyFiltersActive;

  if (isEmptyStateVisible)
    return (
      <div className={styles.page}>
        <div className={styles.main}>
          <EmptyEntryPage
            dataIdPrefix="IncomingInvoice"
            Image={IncomingInvoiceEmptyStateImage}
            header={t('empty_entry_pages.incoming_invoice.header')}
            subheader={t('empty_entry_pages.incoming_invoice.subheader')}
            info={[
              t('empty_entry_pages.incoming_invoice.info.row_1'),
              t('empty_entry_pages.incoming_invoice.info.row_2'),
              t('empty_entry_pages.incoming_invoice.info.row_3'),
              t('empty_entry_pages.incoming_invoice.info.row_4'),
              t('empty_entry_pages.incoming_invoice.info.row_5'),
            ]}
            createButtonLabel={t('empty_entry_pages.outgoing_invoice.create_button_label')}
            onCreateButtonClick={() => push(paths.newIncomingInvoice)}
            importButtonLabel={t('empty_entry_pages.incoming_invoice.create_einvoice_button_label')}
            onImportButtonClick={() => push(paths.newIncomingEInvoice)}
          />
        </div>
      </div>
    );

  return (
    <div className={styles.page}>
      <div className={styles.main}>
        <Tabs sections={sections} switchTabAddon={switchTab} piwikTrackingEnabled />
      </div>
    </div>
  );
};

IncomingInvoices.propTypes = {
  areIncomingInvoicesEmpty: bool.isRequired,
  push: func.isRequired,
  getIncomingInvoices: func.isRequired,
  setPage: func.isRequired,
  isFetching: bool.isRequired,
  sorting: shape({}).isRequired,
  pagination: shape({
    page: number,
    totalPages: number,
  }).isRequired,
  parsedFilters: shape({}),
};

const mapStateToProps = (state) => ({
  isFetching: state.incomingInvoices.isFetching,
  areIncomingInvoicesEmpty: isEmpty(state.incomingInvoices.data),
  parsedFilters: state.incomingInvoices.parsedFilters,
  sorting: state.incomingInvoices.sorting,
  pagination: state.incomingInvoices.pagination,
});

const mapDispatchToProps = {
  push: pushAction,
  getIncomingInvoices: getIncomingInvoicesAction,
  clearFiltersToInitial: clearFiltersToInitialAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncomingInvoices);
